.error-background-image {
  background-image: url("../assets/bg404.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100%;
}
.gradient-bg {
  background: radial-gradient(60% 60% at 50% 50%, #FF5500 0%, #000000 100%);
}
.translation-forward {

  animation: forward 3s infinite alternate ease-in-out;
}
.translation-backward {
  animation: backward 3s infinite alternate ease-in-out;
}
.rotate-border {
  animation: rotation 4s linear infinite;
}
.rotate-border-outer {
  animation: rotation-reverse 6s linear infinite;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotation-reverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes forward {
  from {
    left: 2%;
  }
  to {
    left: 90%;
  }
}
@keyframes backward {
  from {
    left: 90%;
  }
  to {
    left: 2%;
  }
}
