.gradient-heading {
  background: -webkit-linear-gradient(#ffffff 5%, #999999 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient-label {
  background: -webkit-linear-gradient(60deg, #ffffff, #454545);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
