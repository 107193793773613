@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Red Hat Display", sans-serif, verdana;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: #000;
}
