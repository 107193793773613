.container-loader {
  background-color: #000;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
}

.loader {
  border: 10px double #ff3503;
  animation: spin 2s ease-in-out infinite;
}

@keyframes spin {
  0% {
    border-radius: 12%;
    transform: rotate(0deg) scale(1);
    width: 100px;
    height: 100px;
    margin-bottom: 12px;
  }

  50% {
    border-radius: 50%;
    width: 88px;
    height: 88px;
    margin-bottom: 0;
  }

  100% {
    transform: rotate(360deg) scale(1.1);
    border-radius: 12%;
    width: 88px;
    height: 88px;
    margin-bottom: 12px;
  }
}
