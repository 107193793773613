.rotate-positive {
  animation: rotate-clockwise 20s linear infinite;
  transform-origin: center;
}

.rotate-negative {
  animation: rotate-anti-clockwise 10s linear infinite;
  transform-origin: center;
}

.outer-circle {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='900' ry='900' stroke='%23EC3463' stroke-width='1' stroke-dasharray='15%2c 8%2c 12%2c 13' stroke-dashoffset='100' stroke-opacity='0.5' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 50%;
}
.inner-circle {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='92%' ry='92%' stroke='%23EC3463' stroke-width='1' stroke-dasharray='15%2c 8%2c 12%2c 13' stroke-dashoffset='100' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 50%;
}
.gradient-label {
  background: -webkit-linear-gradient(60deg, #ffffff, #454545);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sector-rotation {
  animation: rotate-clockwise 2s linear infinite;
}
.sector-bg {
  background: rgb(255, 51, 0);
  background: radial-gradient(
    circle,
    rgba(255, 51, 0, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

@keyframes rotate-anti-clockwise {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes rotate-clockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.aboutus-wrapper-bg {
  background-image: url("../assets/aboutusWrraperBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.card-gradient-1 {
  background: rgb(255, 51, 0);
  background: linear-gradient(
    160deg,
    rgb(146, 29, 0) 3%,
    rgba(73, 17, 5, 1) 100%
  );
}

.card-gradient-2 {
  background: rgb(255, 51, 0);
  background: linear-gradient(
    -160deg,
    rgba(255, 51, 0, 1) 3%,
    rgba(73, 17, 5, 1) 100%
  );
}
.text-gradient-1 {
  background: -webkit-linear-gradient(-90deg, #9d5032, #2b1812);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-2 {
  background: -webkit-linear-gradient(90deg, #2b1823, #58433f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.crypto-app-gradient {
  position: relative;
  overflow: hidden;
}

.crypto-app-gradient::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: conic-gradient(
    from 320deg,
    hsl(0deg 0% 0%) 0%,
    hsl(12deg 97% 14%) 5%,
    hsl(12deg 97% 28%) 10%,
    hsl(12deg 97% 42%) 16%,
    hsl(12deg 97% 42%) 24%,
    hsl(12deg 97% 28%) 35%,
    hsl(12deg 97% 14%) 51%,
    hsl(0deg 0% 0%) 100%
  );
  animation: rotate-gradient 4s linear infinite;
}

@keyframes rotate-gradient {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
