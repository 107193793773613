.swiper {
  padding-top: 50px;
  padding-bottom: 100px;
}

.swiper-slide {
  position: relative;
  width: 340px;
  height: 400px;
}

.swiper-pagination-bullet {
  background-color: #e3512c;
  width: 12px;
  height: 12px;
}

.swiper-pagination-bullet-active {
  background-color: #ff3503;
}
@media (max-width: 1536px) {
  .swiper {
    padding-bottom: 60px;
  }
}
@media (max-width: 1280px) {
  .swiper {
    padding-bottom: 0px;
  }
}
@media (max-width: 1024px) {
  .swiper-slide {
    max-width: 310px;
    height: 290px;
  }
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
  }
  .swiper {
    padding-bottom: 120px;
  }
}

@media (max-width: 768px) {
  .swiper-slide {
    max-width: 300px;
    height: 280px;
  }
  .swiper {
    padding-bottom: 30px;
  }
}

@media (max-width: 640px) {
  .swiper-slide {
    max-width: auto;
    height: 240px;
  }
}
@media (max-width: 540px) {
  .swiper-slide {
    max-width: auto;
    height: auto;
  }
  .swiper {
    padding-top: 30px;
    padding-bottom: 40px;
  }
}
