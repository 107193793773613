.ue_neon_btn {
  background-color: #111;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 40px;
  overflow: visible;
  transition: 0.5s;
  text-decoration: none;
}
.ue_neon_btn::before {
  content: "";
  position: absolute;
  box-sizing: content-box;
  border-radius: 40px;
  width: 100%;
  height: 100%;
  translate: -50% -50%;
  left: 50%;
  top: 50%;
  z-index: -2;
  transition: all 0.5s;
  animation: rotate1 2000ms linear infinite;
  filter: blur(20px) brightness(1);
  background: conic-gradient(
    from var(--angle),
    #ff003700 50deg,
    #ee472d 360deg
  );
}
.ue_neon_btn::after {
  content: "";
  position: absolute;
  box-sizing: content-box;
  border-radius: calc(40px + 2px);
  width: 100%;
  height: 100%;
  translate: -50% -50%;
  left: 50%;
  top: 50%;
  z-index: -1;
  animation: rotate1 2000ms linear infinite;
  padding: 2px;
  --angle: 0deg;

  background: conic-gradient(
    from var(--angle),
    #ff003700 50deg,
    #ee472d 360deg
  );
  transition: all 0.5s;
}
.ue_neon_btn:hover {
  background-color: #ff5500;
  color: #000000;
  font-weight: 600;
}
.ue_neon_btn:hover::before,
.ue_neon_btn:hover::after {
  transition: all 0.5s;
  animation-play-state: paused;
  background: conic-gradient(from var(--angle), #ff5500 0deg, #ff5500 360deg);
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes rotate1 {
  0% {
    --angle: 0deg;
  }

  100% {
    --angle: 360deg;
  }
}
